.news-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
  margin-top: 20px;
  width: 30%;
  max-width: 400px;
  overflow: hidden;
  transition: 0.4s;
  &:hover {
    box-shadow: 0 10px 38px 0 rgba(0, 0, 0, 0.35);
    transform: translateY(-2%);
  }
  @media (max-width: 1050px) {
    width: 45%;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
  &__header {
    font-size: 1.2rem;
    margin-top: 15px;
    padding: 0 20px;
    color: #233e94;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    transition: 0.2s;
    &:hover {
      color: gray;
    }
  }
  &--home {
    width: 440px;
    @media (max-width: 1100px) {
      width: 45%;
    }
    @media (max-width: 600px) {
      width: 100%;
    }
  }

  &__info-wrapper {
    background-color: white;
    z-index: 1;
    padding-top: 10px;
  }
  &__more {
    padding-top: 15px;
    margin-left: 20px;
    color: rgb(35, 62, 148);
    font-size: 15px;
    &:hover {
      transition: 0.2s;
      color: gray;
    }
    @media (max-width: 900px) {
      font-size: 12px;
    }
  }
  &__date {
    font-size: 15px;
    @media (max-width: 900px) {
      font-size: 12px;
    }
  }
  &__image {
    width: 100%;
    transition: 0.5s;
    &:hover {
      transform: scale(1.05);
    }
  }
  &__excerpt {
    padding: 0 20px;
    font-weight: normal;
  }
  &__line {
    width: calc(100% - 40px);
    margin: 0 auto;
    margin-top: auto;
  }
  &__info {
    padding: 5px 20px 20px 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
  }
}
