.news-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 650px)
    {
     justify-content: center;
    }
  &--home{
    @media screen and (max-width: 1100px)
    {
     justify-content: space-between;
    }
    @media screen and (max-width: 600px)
    {
     justify-content: center;
    }
  }
}

