.footer-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
    @media screen and (max-width: 600px)
    {
    flex-wrap: wrap;

    } 
    p{
      font-weight: 400;
      margin-bottom: 0;
      padding: 0 10px;
      
      @media screen and (max-width: 1550px)
    {
   
    font-size:rem(12px);
    }
      
      @media screen and (max-width: 1100px)
    {
   
    font-size:rem(14px);
    }
      @media screen and (max-width: 800px)
    {
   
    font-size:rem(12px);
    }
      @media screen and (max-width: 700px)
    {
   
    font-size:rem(10px);
    }
      @media screen and (max-width: 600px)
    {
   width: 100%;
    font-size:rem(14px);
    }
     
    }
    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span{
        transition: color .3s ease-in-out;
      }
      &:hover span{
        color: $color-main;
      }
      img{
        margin-left: 15px;
      }
    }
}
.footer-big {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  height: 160px;
  align-items: center;
  z-index: 1;
  
  &__image {
    width: 100%;
    position: relative;
    top: 4px;
  }

  &__navigation-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    float: left;
  }

  &__links-container {
    display: flex;
    list-style: none;
    justify-content: space-around;
    margin-left: 100px;
    margin-top: 15px;
  }
  &__image-wrapper{
    background: linear-gradient(#e7e9f5, #fff);
  }
  &__link {
    font-size: 18px;
    text-decoration: none;
    color: white;
    opacity: 0.1;
    margin-top: 50px;
  }
  &__menu{
    display: flex;
    background-color: #233e94;
    height: 160px;
    @media screen and (max-width: 1100px)
    {
    height: auto;
    
    } 
     @media screen and (max-width: 500px)
    {
      height: auto;

    }
  }
  &__logo{
    margin: 0 auto;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-right: 0  20px 0 0;
    @media screen and (max-width: 1500px)
    {
   
    width: 30%;
    
    } 
    @media screen and (max-width: 1100px)
    {
      width: 25%;
      margin-right: 15px;

    }
    @media screen and (max-width: 600px)
    {
      width: 50%;
    
    } 
    
    
    

  }
  &__logo-image{
    @media screen and (max-width: 1500px)
    {
   height: auto;
    
    } 
  }
  
  &__menuItem::before{
    display: none;
    padding: 0 20px;
    
    }
 
  &__menuItem{
    @media screen and (max-width: 600px)
    {
      width: 45%;
      
    }
    
    }
 
  &__menuLink {
    color: rgba($color: white, $alpha: .2);
    width: 100%;
    
    &--fullscreen {
      color: white;
      font-size: rem(28px);
    }
    @media screen and (max-width: 1550px)
    {
   
    font-size:rem(16px);
    }
      
      @media screen and (max-width: 1100px)
    {
   
    font-size:rem(12px);
    }
      @media screen and (max-width: 800px)
    {
   
    font-size:rem(10px);
    }
      @media screen and (max-width: 700px)
    {
   
    font-size:rem(8px);
    }
      @media screen and (max-width: 600px)
    {
   
    font-size:rem(16px);
    }
      @media screen and (max-width: 500px)
    {
   
    font-size:rem(15px);
    }
      @media screen and (max-width: 300px)
    {
   
    font-size:rem(13px);
    }
     
     
     
   
      
     
    &:active, &--active, &--open, &:hover {
      color: rgba($color: white, $alpha: 1);
    }
  }
  &__menuList{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    @media screen and (max-width: 1300px)
    {
    padding: 0 15px;    
    } 
    @media screen and (max-width: 1100px)
    {
    padding: 0 10px;    
    } 
    @media screen and (max-width: 600px)
    {
      width: 100%;
      padding: 0 8px; 
      flex-wrap: wrap;
    
    } 
    
  }
  &__nav {
    width: 75%;
    display: flex;
    margin:  0 0 0 auto;
    align-items: center;
    flex-grow: 1;
    margin-left: 5%;
    @media screen and (max-width: 1300px)
    {
      margin-left: 2%;
    width: 75%;
    
    
    } 
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }
  
}
ul li{
  padding-left: 0;
}
.footers-small {
  display: flex;
  height: 90px;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  background-color: white;
  
  
  
}
.footer__logo {
  width: 280px;
  height: 80px;
      @media screen and (max-width: 1520px)
    {
   
    width: 220px;
    margin-right: 15px;
    }
      @media screen and (max-width: 1100px)
    {
   
    width: 200px;
    margin-right: 10px;
    
    }
    
      
}
