.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 120px;

  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }

  &__logo{
    @media screen and (max-width: 1650px)
  {
 
  width: 280px;
  }
    @media screen and (max-width: 1520px)
  {
 
    width: 230px;
  }
    @media screen and (max-width: 1100px)
  {
 
    width: 210px;
  }
    @media screen and (max-width: 700px)
  {
 
    width:180px;
  }
    
  }

  &__nav {
    width: 75%;
   margin-left: 2%;
    @include mq($until: md) {
      width:100%;
    }
    
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;

    width: 100%;

    &--fullscreen {
      flex-direction: column;
    }
  }
  &__banner{
    width: 100%;
    height: 80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include mq($until: xxl){
      height: 70vh;
    }
    @include mq($until: lg){
      height: 50vh;
    }
    @include mq($until: sm){
      height: 40vh;
    }
    @include mq($until: xs){
      height: 30vh;
    }
  }
  &__image{
    min-width: 26px;
    
  }
  &__flags-container{
    width: 10%;
    margin-left: 4%;
    @media screen and (max-width: 991px)
    {
      margin-left: 3%;
    }
  }

  &__menuItem {
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
    &::marker{
      display: none;
    }
  }
  &__hamburger{
    @media screen and (max-width: 991px)
    {
    margin-left: auto;
    }
  }
  &__submenu{
    background-color: #dddfeb !important;
  }
  &__line{
    background-color: rgb(35, 62, 148);
    margin: 0;
  }
  &__menuLink {
    color: #3e3e3e;
    white-space: nowrap;
    @media screen and (max-width: 1650px)
  {
 
  font-size:rem(16px);
  }
    @media screen and (max-width: 1540px)
  {
 
  font-size:rem(16px);
  }
    @media screen and (max-width: 1100px)
  {
 
  font-size:rem(14px);
  }
    @media screen and (max-width: 991px)
  {
  color:white;
  font-size:rem(25px);
  }
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: $color-main;
      @media screen and (max-width: 991px)
      {
       color: grey;
     }
    }
  }
}