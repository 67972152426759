.photos {
  
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 90px;
  &__first {
    background-image: url('../../images/content_main/AdobeStock_372877698.jpg');
    height: 300px;
    width: 59%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: 700px)
    {
   
    width: 100%;
    margin-bottom: 2%;
    }
  }
  &__second {
    width: 39%;
    height: 300px;
    background-image: url('../../images/content_main/AdobeStock_212896645.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: 700px)
    {
   
    width: 100%;
    }
  }
  &__third {
    width: 100%;
    height: 300px;
    background-image: url('../../images/content_main/AdobeStock_113976198.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 2%;
    margin-bottom: 2%;
    @media screen and (max-width: 700px)
    {
   
    width: 100%;
    }
  }
  &__fourth {
    width: 39%;
    height: 300px;
    background-image: url('../../images/content_main/AdobeStock_67426708.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: 700px)
    {
   
    width: 100%;
    margin-bottom: 2%;
    }
  }
  &__fifth {
    width: 59%;
    height: 300px;
    background-image: url('../../images/content_main/AdobeStock_67385325.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: 700px)
    {
   
    width: 100%;
    }
  }
}
