.section-info {
  padding: 20px;
  height: auto;
  background-color: white;
  

  &__header {
    text-transform: uppercase;
    color: rgb(35, 62, 148);
    margin-top: 60px;
    margin-bottom: 50px;
    font-size: 18px;
    font-weight: bold;
  }
 
  &__data-wrapper{
    display: flex;
    @media screen and (max-width: 1520px)
    {
      margin-top: 10px;
      justify-content: space-between;
    }
    @media screen and (max-width: 500px)
    {
      flex-direction: column;
    }
  }
  &__data {
    margin-top: 40px;
    margin-left: 20px;
    width: 40%;
    @media screen and (max-width: 1520px)
    {
      width: 40%;
      margin-top: 0;
      margin-left: 0;
    }
    @media screen and (max-width: 500px)
    {
   
      width: 100%;
    }
     
    
  }
  
  &__person {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 40px ;
    margin-left: 40px;
    @media screen and (max-width: 700px)
    {
      margin-top: 0px ;
      margin-left: 0px;
      flex-direction: column;
    }
    @media screen and (max-width: 500px)
    {
      margin-top: 10px ;
      margin-left: 0px;
      flex-direction: column;
    }
    
  }
  &__address {
    font-size:rem(16px);
    font-weight: bold;
    margin: 0;
    @media screen and (max-width: 700px)
    {
      font-size:rem(15px);
    }
  }
  &__number {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    @media screen and (max-width: 700px)
    {
      font-size:rem(15px);
    }
    
  }
  &__mail {
    font-size: 16px;
    font-weight: bold;
    @media screen and (max-width: 700px)
    {
   
      font-size:rem(15px);
    }
  }
  &__name {
    font-size: 18px;
    font-weight: bold;
    margin: 0 auto;
    display: block;
    margin-left: 10px;
    @media screen and (max-width: 700px)
    {
   
      font-size:rem(16px);
    }
    @media screen and (max-width: 400px)
    {
   
      font-size:rem(14px);
    }
  }
  &__title {
    font-weight: bold;
    font-size: 14px;
    margin-left: 10px;
    @media screen and (max-width: 700px)
    {
   
      font-size:rem(14px);
    }
    @media screen and (max-width: 400px)
    {
   
      font-size:rem(12px);
    }
  }
  &__image{
    height: 60px;
    margin-bottom: 15px;
  }
  &__consul-photo{
    width: 134px;
  }
}
