.main {
  &__wrapper{
    display: flex;
    @media screen and (max-width: 1100px)
    {
    flex-direction: column;
    align-items: center;
  }
  }
  &__article{
    width: 100%;
  }
 
  &__header {
    font-weight: bold;
    font-size: 24px;
    margin-top: 70px  !important;
    color: rgb(35, 62, 148);
  }
  &__text p {
    color: rgb(63, 63, 65);
    font-size: 20px;
  }
  &__link {
    color: rgb(35, 62, 148);
    font-weight: bold;
    font-size: 18px;
    display: block;
    margin-top: 50px;
    margin-bottom: 90px;
    text-decoration: underline;
  }
 
}


.aside{
  margin-top: 70px;
  width: 440px;
  margin-left: 120px;
  @media screen and (max-width: 1750px)
    {
     margin-top: 70px;
      width: 30%;
      margin-left: 120px;      
    } 
  @media screen and (max-width: 1100px)
    {
     margin-top: 0;
      width: 100%;
      margin-left: 0;      
    } 
 
  &__title-container{
    margin-top: 20px;
    padding:  0  20px 20px 20px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 25px;
    font-weight: bold;
    @media screen and (max-width: 1600px)
    {
      padding:  0  10px 10px 10px; 
    } 
    
  }
  &__title{
    font-weight: bold;
    font-size: 22px;
    color: rgb(35, 62, 148);
    text-transform: uppercase;
    @media screen and (max-width: 500px)
  {
  font-size: 18px;
}
  }
  &__see-more{
    font-size: 12px;
    color: rgb(35, 62, 148);
    &:hover{
      transition: 0.2s;
      color: gray;
  }
  }
}
.margin-bt{
  margin-bottom: 40px !important;
}

.map{
  &__wrapper{
  height: 350px;
  width: 100%;

  .map{
    height: 100%;
    }
  }
}